import useAuthenticatedFetch from "@/hooks/useAuthenticatedFetch.ts";
import { apiUrl } from "@/utils/api.ts";
import { type UseQueryResult, useQuery } from "@tanstack/react-query";
import {
    createDataSelector,
    createResourceCollectionSelector,
    handleJsonApiError,
} from "jsonapi-zod-query";
import { z } from "zod";

const shippingAddressAttributesSchema = z.object({
    companyName: z.string(),
    addressLineOne: z.string(),
    addressLineTwo: z.string(),
    city: z.string(),
    zipCode: z.string(),
    region: z.string(),
    countryCode: z.string(),
});

const shippingAddressesSelector = createDataSelector(
    createResourceCollectionSelector({
        type: "shipping_address",
        attributesSchema: shippingAddressAttributesSchema,
    }),
);

export type ShippingAddress = ReturnType<typeof shippingAddressesSelector>[number];

export const useShippingAddressesQuery = (enabled = true): UseQueryResult<ShippingAddress[]> => {
    const fetch = useAuthenticatedFetch();

    return useQuery({
        queryKey: ["shipping-addresses"],
        queryFn: async ({ signal }) => {
            const response = await fetch(apiUrl("/shipping-addresses"), {
                signal,
                headers: {
                    accept: "application/vnd.api+json",
                },
            });
            await handleJsonApiError(response);
            return response.json();
        },
        select: shippingAddressesSelector,
        enabled,
    });
};
