import { useAuth0 } from "@auth0/auth0-react";
import { Button, Container, Link, Typography } from "@mui/material";
import type { ReactNode } from "react";
import { Link as RouterLink, useParams } from "react-router-dom";

type Params = {
    orderNumber: string;
};

const OrderConfirmationPage = (): ReactNode => {
    const { isAuthenticated } = useAuth0();
    const { orderNumber } = useParams<Params>();

    return (
        <Container>
            <Typography variant="h5">Order received</Typography>

            <Typography paragraph>
                Thank you for your order and for choosing SBI Fine Fabric Finishing.
            </Typography>

            {orderNumber ? (
                <>
                    <Typography paragraph>We have received your order #{orderNumber}.</Typography>
                    <Typography paragraph>
                        You will receive an email order confirmation shortly.
                    </Typography>
                </>
            ) : (
                <>
                    <Typography paragraph>
                        Please feel free to call or email us with any questions.
                    </Typography>
                    <Typography paragraph>
                        Customer Service
                        <br />
                        SBI Fine Fabric Finishing
                        <br />
                        Phone: <Link href="tel:+19036751440">(903) 675-1440</Link>
                        <br />
                        <Link href="mailto:orders@sbifinishing.com">orders@sbifinishing.com</Link>
                    </Typography>
                </>
            )}

            {isAuthenticated && (
                <Button component={RouterLink} to="/" variant="outlined">
                    Return to Dashboard
                </Button>
            )}
        </Container>
    );
};

export default OrderConfirmationPage;
