import Layout from "@/components/Layout/index.ts";
import RootErrorBoundary from "@/components/RootErrorBoundary/index.ts";
import HomePage from "@/pages/HomePage/index.ts";
import InitiateLoginPage from "@/pages/InitiateLoginPage/index.ts";
import OrderConfirmationPage from "@/pages/OrderConfirmationPage/index.ts";
import OrderPage from "@/pages/OrderPage/index.ts";
import { type RouteObject, createBrowserRouter } from "react-router-dom";

const pathRoutes: RouteObject[] = [
    {
        path: "/",
        element: <HomePage />,
    },
    {
        path: "/initiate-login",
        element: <InitiateLoginPage />,
    },
    {
        path: "/order",
        element: <OrderPage />,
    },
    {
        path: "/order-confirmation/:orderNumber?",
        element: <OrderConfirmationPage />,
    },
];

const rootRoute: RouteObject = {
    path: "/",
    element: <Layout />,
    errorElement: <RootErrorBoundary />,
    children: pathRoutes,
};

export const router = createBrowserRouter([rootRoute]);

if (import.meta.hot) {
    import.meta.hot.dispose(() => router.dispose());
}
