import { type SxProps, Typography } from "@mui/material";
import { countries } from "countries-list";
import { formatAddress } from "localized-address-format";
import type { ReactNode } from "react";

type Address = {
    firstName?: string;
    lastName?: string;
    companyName: string;
    addressLineOne: string;
    addressLineTwo: string;
    region: string;
    city: string;
    zipCode: string;
    countryCode: string;
};

type Props = {
    address: Address;
    sx?: SxProps;
};

const PostalAddress = ({ address, sx }: Props): ReactNode => {
    const nameParts: string[] = [];

    if (address.firstName) {
        nameParts.push(address.firstName);
    }

    if (address.lastName) {
        nameParts.push(address.lastName);
    }

    return (
        <Typography whiteSpace="pre-line" sx={sx}>
            {formatAddress({
                postalCountry: address.countryCode,
                administrativeArea: address.region,
                locality: address.city,
                postalCode: address.zipCode,
                organization: address.companyName,
                name: nameParts.join(" "),
                addressLines: [address.addressLineOne, address.addressLineTwo],
            }).join("\n")}
            <br />
            {countries[address.countryCode as keyof typeof countries].name}
        </Typography>
    );
};

export default PostalAddress;
