import type { ShippingAddress } from "@/queries/shipping-addresses.ts";
import { errorMap } from "@/utils/zod.ts";
import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Typography } from "@mui/material";
import type { ReactNode } from "react";
import { useMemo } from "react";
import { type DeepPartial, type UseFormReturn, useForm } from "react-hook-form";
import { z } from "zod";
import OrderStepper, { type OrderStepperProps } from "../OrderStepper.tsx";
import BillingInformationFieldSet, {
    billingInformationSchema,
} from "./BillingInformationFieldSet.tsx";
import { CREATE_NEW_SHIPPING_ADDRESS_OPTION } from "./ShippingAddressSelect.tsx";
import ShippingInformationFieldSet, {
    shippingInformationSchema,
} from "./ShippingInformationFieldSet.tsx";

type Props = {
    existingValues: Partial<CustomerInformationFormValues>;
    shippingAddresses: ShippingAddress[];
    onSubmit: (values: CustomerInformationFormValues) => void;
    stepperProps: OrderStepperProps;
};

const schema = z
    .object({
        billingInformation: billingInformationSchema,
        selectedShippingAddress: z.custom<
            typeof CREATE_NEW_SHIPPING_ADDRESS_OPTION | ShippingAddress
        >(),
        sameAsBilling: z.boolean(),
        shippingInformation: shippingInformationSchema,
    })
    .transform((values) => {
        const { selectedShippingAddress, sameAsBilling, ...rest } = values;
        return rest;
    });

type FieldValues = z.input<typeof schema>;
type TransformedValues = z.output<typeof schema>;
export type CustomerInformationUseFormReturn = UseFormReturn<
    FieldValues,
    unknown,
    TransformedValues
>;
export type CustomerInformationFormValues = TransformedValues;

const getDefaultSelectedShippingAddress = (
    shippingInformation: TransformedValues["shippingInformation"] | undefined,
    shippingAddresses: ShippingAddress[],
): FieldValues["selectedShippingAddress"] => {
    if (!shippingInformation) {
        return CREATE_NEW_SHIPPING_ADDRESS_OPTION;
    }

    if (shippingInformation.shippingAddress.type !== "stored") {
        return CREATE_NEW_SHIPPING_ADDRESS_OPTION;
    }

    const shippingAddressId = shippingInformation.shippingAddress.id;
    const shippingAddress = shippingAddresses.find((address) => address.id === shippingAddressId);

    if (!shippingAddress) {
        return CREATE_NEW_SHIPPING_ADDRESS_OPTION;
    }

    return shippingAddress;
};

const getDefaultValues = (
    existingValues: Partial<TransformedValues>,
    shippingAddresses: ShippingAddress[],
): DeepPartial<FieldValues> => {
    const result: DeepPartial<FieldValues> = {
        billingInformation: existingValues.billingInformation ?? {
            countryCode: "US",
        },
        shippingInformation: existingValues.shippingInformation
            ? {
                  ...existingValues.shippingInformation,
                  shippingInsurance:
                      existingValues.shippingInformation.shippingInsurance.type === "custom"
                          ? {
                                  ...existingValues.shippingInformation.shippingInsurance,
                                  amount: existingValues.shippingInformation.shippingInsurance.amount.toString(),
                              }
                          : existingValues.shippingInformation.shippingInsurance,
              }
            : {
                  shippingAddress: {
                      type: "new",
                      countryCode: "US",
                  },
                  shippingInsurance: {
                      type: "standard",
                  },
              },
        sameAsBilling:
            existingValues.shippingInformation?.shippingAddress.type === "same_as_billing",
        selectedShippingAddress: getDefaultSelectedShippingAddress(
            existingValues.shippingInformation,
            shippingAddresses,
        ),
    };

    if (!result.shippingInformation) {
        result.shippingInformation = {
            shippingAddress: { type: "new" },
            shippingInsurance: { type: "standard" },
        };
    }

    return result;
};

const CustomerInformationForm = ({
    existingValues,
    shippingAddresses,
    stepperProps,
    onSubmit,
}: Props): ReactNode => {
    const defaultValues = useMemo(() => {
        return getDefaultValues(existingValues, shippingAddresses);
    }, [existingValues, shippingAddresses]);

    const form = useForm<FieldValues, unknown, TransformedValues>({
        resolver: zodResolver(schema, { errorMap }),
        defaultValues,
    });

    return (
        <Box sx={{ pb: 8 }} component="form" onSubmit={form.handleSubmit(onSubmit)} noValidate>
            <Typography variant="h6" sx={{ mb: 2 }}>
                Billing Information
            </Typography>
            <BillingInformationFieldSet form={form} />

            <Typography variant="h6" sx={{ mb: 2 }}>
                Shipping Information
            </Typography>
            <ShippingInformationFieldSet form={form} shippingAddresses={shippingAddresses} />

            <OrderStepper {...stepperProps} />
        </Box>
    );
};

export default CustomerInformationForm;
