import PostalAddress from "@/components/PostalAddress/index.ts";
import { useShippingAddressesQuery } from "@/queries/shipping-addresses.ts";
import { useAuth0 } from "@auth0/auth0-react";
import EditIcon from "@mui/icons-material/Edit.js";
import {
    Card,
    CardContent,
    CardHeader,
    IconButton,
    LinearProgress,
    type SxProps,
    Typography,
} from "@mui/material";
import { useMemo } from "react";
import type { ReactNode } from "react";
import type { CustomerInformationFormValues } from "../CustomerInformationStep/index.ts";
import {
    carrierOptions,
    fedexServiceOptions,
    paymentOptions,
    resolveOptionLabel,
    shippingInsuranceOptions,
    upsServiceOptions,
} from "../options.ts";

const currencyFormatter = new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" });

type Props = {
    billingInformation: CustomerInformationFormValues["billingInformation"];
    shippingInformation: CustomerInformationFormValues["shippingInformation"];
    onStepChange: (step: number) => void;
    sx?: SxProps;
};

const ShippingInformationCard = ({
    billingInformation,
    shippingInformation,
    onStepChange,
    sx,
}: Props): ReactNode => {
    const { isAuthenticated } = useAuth0();
    const shippingAddressesQuery = useShippingAddressesQuery(isAuthenticated);

    const shippingAddress = useMemo(() => {
        const { shippingAddress } = shippingInformation;

        if (shippingAddress.type === "new") {
            return shippingAddress;
        }

        if (shippingAddress.type === "same_as_billing") {
            return billingInformation;
        }

        if (shippingAddressesQuery.isPending) {
            return null;
        }

        if (!shippingAddressesQuery.data) {
            throw new Error("Failed to load shipping address");
        }

        const selectedAddress = shippingAddressesQuery.data.find(
            (address) => address.id === shippingAddress.id,
        );

        if (!selectedAddress) {
            throw new Error("Selected shipping address not available anymore");
        }

        return selectedAddress;
    }, [
        shippingInformation,
        billingInformation,
        shippingAddressesQuery.data,
        shippingAddressesQuery.isPending,
    ]);

    if (shippingAddressesQuery.isError) {
        throw shippingAddressesQuery.error;
    }

    if (!shippingAddress) {
        return (
            <Card sx={sx}>
                <CardHeader
                    title="Shipping Information"
                    action={
                        <IconButton
                            onClick={() => {
                                onStepChange(0);
                            }}
                        >
                            <EditIcon />
                        </IconButton>
                    }
                />
                <CardContent>
                    <LinearProgress />
                </CardContent>
            </Card>
        );
    }

    return (
        <Card sx={sx}>
            <CardHeader
                title="Shipping Information"
                action={
                    <IconButton
                        onClick={() => {
                            onStepChange(0);
                        }}
                    >
                        <EditIcon />
                    </IconButton>
                }
            />
            <CardContent>
                <PostalAddress address={shippingAddress} sx={{ mb: 2 }} />
                <Typography>
                    {shippingInformation.attention !== "" && (
                        <>
                            <strong>Attention:</strong> {shippingInformation.attention}
                            <br />
                        </>
                    )}
                    <strong>Carrier:</strong>{" "}
                    {resolveOptionLabel(carrierOptions, shippingInformation.carrier)}
                    <br />
                    <strong>Service:</strong>{" "}
                    {shippingInformation.carrier === "other"
                        ? shippingInformation.service
                        : shippingInformation.carrier === "fedex"
                          ? resolveOptionLabel(fedexServiceOptions, shippingInformation.service)
                          : resolveOptionLabel(upsServiceOptions, shippingInformation.service)}
                    <br />
                    <strong>Payment:</strong>{" "}
                    {resolveOptionLabel(paymentOptions, shippingInformation.payment)}
                    <br />
                    {"shippingAccountNumber" in shippingInformation && (
                        <>
                            <strong>Shipping Account Number:</strong>{" "}
                            {shippingInformation.shippingAccountNumber}
                            <br />
                        </>
                    )}
                    <strong>Insurance:</strong>{" "}
                    {resolveOptionLabel(
                        shippingInsuranceOptions,
                        shippingInformation.shippingInsurance.type,
                    )}
                    <br />
                    {shippingInformation.shippingInsurance.type === "custom" && (
                        <>
                            <strong>Custom amount:</strong>{" "}
                            {currencyFormatter.format(shippingInformation.shippingInsurance.amount)}
                        </>
                    )}
                </Typography>
            </CardContent>
        </Card>
    );
};

export default ShippingInformationCard;
