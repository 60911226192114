import useAuthenticatedFetch from "@/hooks/useAuthenticatedFetch.ts";
import { apiUrl } from "@/utils/api.ts";
import { useAuth0 } from "@auth0/auth0-react";
import { type UseMutationResult, useMutation } from "@tanstack/react-query";
import { handleJsonApiError } from "jsonapi-zod-query";
import { z } from "zod";

type Address = {
    companyName: string;
    firstName?: string;
    lastName?: string;
    addressLineOne: string;
    addressLineTwo: string;
    city: string;
    zipCode: string;
    region: string;
    countryCode: string;
};

type NewShippingAddress = {
    type: "new";
} & Address;

type StoredShippingAddress = {
    type: "stored";
    id: string;
};

type SameAsBillingShippingAddress = {
    type: "same_as_billing";
};

type FedexCarrier = {
    carrier: "fedex";
    shippingAccountNumber: string;
};

type NonFedexCarrier = {
    carrier: "ups" | "other";
};

type SbiUpsPayment = {
    payment: "sbi_ups";
};

type PrePaidPayment = {
    payment: "prepaid";
};

type CollectPayment = {
    payment: "collect";
    shippingAccountNumber: string;
};

type ThirdPartyPayment = {
    payment: "third_party";
    shippingAccountNumber: string;
};

type StandardShippingInsurance = {
    type: "standard";
};

type CustomShippingInsurance = {
    type: "custom";
    amount: number;
};

export type CustomerInformation = {
    billingInformation: Address & {
        phoneNumber: string;
        emailAddress: string;
        poNumber: string;
    };
    shippingInformation: {
        service: string;
        shippingAddress: NewShippingAddress | StoredShippingAddress | SameAsBillingShippingAddress;
        attention: string;
        shippingInsurance: StandardShippingInsurance | CustomShippingInsurance;
    } & (SbiUpsPayment | PrePaidPayment | CollectPayment | ThirdPartyPayment) &
        (FedexCarrier | NonFedexCarrier);
};

type FlameRetardantService = {
    type: "flame_retardant";
    flammabilityCode: string;
    rush: string | null;
};

type LabService = {
    type: "lab_service";
    flammabilityCode: string;
    rush: string | null;
};

type BackingService = {
    type: "backing";
    backing: string;
    rush: string | null;
};

type LaminationService = {
    type: "lamination_service";
};

type OtherService = {
    type: "other_service";
    otherService: string;
    rush: string | null;
};

type StainProtectionService = {
    type: "stain_protection";
    stainProtection: string;
    rush: string | null;
};

export type Fabric = {
    yards: string;
    fiberContent: string;
    specialCare: boolean;
    pattern: string;
    plaidOrStriped: boolean;
    color: string;
    manufacturer: string;
    trim: string | null;
    sidemarks: string;
    specialInstructions: string;
    fabricFace?: string;
    services: (
        | FlameRetardantService
        | LabService
        | BackingService
        | LaminationService
        | OtherService
        | StainProtectionService
    )[];
};

type CreateOrderSubmissionValues = {
    attributes: {
        customerInformation: CustomerInformation;
        fabric: Fabric;
        captchaToken: string | null;
    };
};

const createOrderSubmissionResultSchema = z.object({
    meta: z.object({
        orderNumber: z.string().nullable(),
    }),
});

export const useCreateOrderSubmissionMutation = (): UseMutationResult<
    string | null,
    Error,
    CreateOrderSubmissionValues
> => {
    const { isAuthenticated } = useAuth0();
    const authenticatedFetch = useAuthenticatedFetch();
    const fetch = isAuthenticated ? authenticatedFetch : window.fetch;

    return useMutation({
        mutationFn: async (values) => {
            const response = await fetch(apiUrl("/order-submissions"), {
                method: "POST",
                headers: {
                    "content-type": "application/vnd.api+json",
                    accept: "application/vnd.api+json",
                },
                body: JSON.stringify({
                    data: {
                        type: "order_submission",
                        attributes: values.attributes,
                    },
                }),
            });
            await handleJsonApiError(response);

            const raw = await response.json();
            const result = createOrderSubmissionResultSchema.parse(raw);
            return result.meta.orderNumber;
        },
    });
};
