import useAuthenticatedFetch from "@/hooks/useAuthenticatedFetch.ts";
import { apiUrl } from "@/utils/api.ts";
import { type UseQueryResult, useQuery } from "@tanstack/react-query";
import { createDataSelector, createResourceSelector, handleJsonApiError } from "jsonapi-zod-query";
import { z } from "zod";
import { zj } from "zod-joda";

const userInformationAttributesSchema = z.object({
    termsAndConditions: z.object({
        acceptedOn: zj.localDate().nullable(),
        acceptanceRequired: z.boolean(),
    }),
});

const userInformationSelector = createDataSelector(
    createResourceSelector({
        type: "user_information",
        attributesSchema: userInformationAttributesSchema,
    }),
);

export type UserInformation = ReturnType<typeof userInformationSelector>;

export const useUserInformationQuery = (enabled = true): UseQueryResult<UserInformation> => {
    const fetch = useAuthenticatedFetch();

    return useQuery({
        queryKey: ["user-information"],
        queryFn: async ({ signal }) => {
            const response = await fetch(apiUrl("/user-information"), {
                signal,
                headers: {
                    accept: "application/vnd.api+json",
                },
            });
            await handleJsonApiError(response);
            return response.json();
        },
        select: userInformationSelector,
        enabled,
        retry: false,
    });
};
