import { PhoneNumberInput } from "@/components/Form/index.ts";
import { Stack } from "@mui/material";
import { isValidPhoneNumber } from "libphonenumber-js/min";
import { RhfTextField } from "mui-rhf-integration";
import type { ReactNode } from "react";
import { z } from "zod";
import AddressFieldSet, { addressSchema } from "./AddressFieldSet.tsx";
import type { CustomerInformationUseFormReturn } from "./CustomerInformationForm.tsx";

export const billingInformationSchema = addressSchema
    .omit({ firstName: true, lastName: true })
    .merge(
        z.object({
            firstName: z.string().trim().min(1),
            lastName: z.string().trim().min(1),
        }),
    )
    .and(
        z.object({
            phoneNumber: z.string().trim().refine(isValidPhoneNumber, "Invalid phone number"),
            emailAddress: z.string().trim().email(),
            poNumber: z.string().trim().default(""),
        }),
    );

type Props = {
    form: CustomerInformationUseFormReturn;
};

const BillingInformationFieldSet = ({ form }: Props): ReactNode => {
    return (
        <Stack spacing={2} sx={{ mb: 2 }}>
            <AddressFieldSet prefix="billingInformation" control={form.control} nameIncluded />
            <Stack spacing={2} direction={{ xs: "column", sm: "row" }}>
                <RhfTextField
                    control={form.control}
                    label="Phone"
                    name="billingInformation.phoneNumber"
                    required
                    InputProps={{
                        inputComponent: PhoneNumberInput,
                    }}
                    autoComplete="tel"
                    sx={{ width: { sm: "33%" } }}
                />
                <RhfTextField
                    control={form.control}
                    label="Email"
                    name="billingInformation.emailAddress"
                    required
                    autoComplete="email"
                    sx={{ width: { sm: "33%" } }}
                />
                <RhfTextField
                    control={form.control}
                    name="billingInformation.poNumber"
                    label="PO Number"
                    sx={{ flexGrow: 1, width: "33%" }}
                />
            </Stack>
        </Stack>
    );
};

export default BillingInformationFieldSet;
