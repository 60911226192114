import {
    Autocomplete,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
} from "@mui/material";
import { type ReactNode, useEffect, useMemo, useState } from "react";
import { type ServiceType, serviceOptions } from "../options.ts";
import { trimSupportedServices } from "./FabricForm.tsx";
import type { FabricUseFormReturn } from "./FabricForm.tsx";

type ServiceTypeOption = {
    value: ServiceType;
    label: string;
    trimSupported: boolean;
};

type Props = {
    open: boolean;
    onClose: () => void;
    onSelect: (serviceType: ServiceType) => void;
    form: FabricUseFormReturn;
};

const AddServiceDialog = ({ open, onClose, onSelect, form }: Props): ReactNode => {
    const options = useMemo(() => {
        return serviceOptions.map(({ value, label }) => ({
            value,
            label,
            trimSupported: trimSupportedServices.includes(value),
        }));
    }, []);
    const [selectedService, setSelectedService] = useState<ServiceTypeOption | null>(null);
    const trimSelected = form.watch("trim") !== "no_trim";

    useEffect(() => {
        if (!open) {
            setSelectedService(null);
        }
    }, [open]);

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>Add Service</DialogTitle>
            <DialogContent dividers>
                <Autocomplete
                    renderInput={(props) => <TextField {...props} label="Service" />}
                    options={options}
                    getOptionLabel={(option) => option.label}
                    getOptionDisabled={(option) => trimSelected && !option.trimSupported}
                    value={selectedService}
                    onChange={(_event, value) => {
                        setSelectedService(value);
                    }}
                    disableClearable={selectedService !== null}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button
                    disabled={selectedService === null}
                    onClick={() => {
                        if (selectedService) {
                            onSelect(selectedService.value);
                        }
                    }}
                >
                    Add
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddServiceDialog;
