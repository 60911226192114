import AddTaskIcon from "@mui/icons-material/AddTask";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Collapse,
    IconButton,
} from "@mui/material";
import type { ReactNode } from "react";
import { useState } from "react";
import type { Control, FieldValues } from "react-hook-form";
import { type FieldPath, useController } from "react-hook-form";

type Props<TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>> = {
    control: Control<TFieldValues>;
    name: TName;
    title: string;
    content: ReactNode;
    buttonLabel: string;
    initiallyOpen?: boolean;
};

const Approval = <TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>({
    control,
    name,
    title,
    content,
    buttonLabel,
    initiallyOpen = false,
}: Props<TFieldValues, TName>): ReactNode => {
    const [expanded, setExpanded] = useState(initiallyOpen);
    const { field, fieldState } = useController({ control, name });

    return (
        <Card>
            <CardHeader
                title={title}
                action={
                    <IconButton
                        onClick={() => {
                            setExpanded(!expanded);
                        }}
                        color={fieldState.error ? "error" : field.value ? "success" : "info"}
                        ref={field.ref}
                    >
                        {field.value ? <CheckCircleIcon /> : <AddTaskIcon />}
                    </IconButton>
                }
            />
            <Collapse in={expanded}>
                <CardContent>{content}</CardContent>
                <CardActions>
                    <Button
                        variant="contained"
                        onClick={() => {
                            setExpanded(false);
                            field.onChange(true);
                        }}
                    >
                        {buttonLabel}
                    </Button>
                </CardActions>
            </Collapse>
        </Card>
    );
};

export default Approval;
