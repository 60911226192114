import useAuthenticatedFetch from "@/hooks/useAuthenticatedFetch.ts";
import { apiUrl } from "@/utils/api.ts";
import { type UseMutationResult, useMutation, useQueryClient } from "@tanstack/react-query";
import { handleJsonApiError } from "jsonapi-zod-query";

export const useAcceptTermsAndConditionsMutation = (): UseMutationResult<void, Error, void> => {
    const fetch = useAuthenticatedFetch();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async () => {
            const response = await fetch(apiUrl("/user-information/accept-terms-and-conditions"), {
                method: "POST",
            });
            await handleJsonApiError(response);
        },
        onSuccess: () => {
            void queryClient.invalidateQueries({
                queryKey: ["user-information"],
            });
        },
    });
};
