import { Box } from "@mui/material";
import type { ReactNode } from "react";

type Props = {
    children: ReactNode;
    minWidth: number;
    gap?: number;
};

const AutoFillGrid = ({ children, minWidth, gap = 2 }: Props): ReactNode => (
    <Box
        sx={{
            display: "grid",
            gridTemplateColumns: {
                xs: "1fr",
                sm: `repeat(auto-fill, minmax(${minWidth}px, 1fr))`,
            },
            gap,
        }}
    >
        {children}
    </Box>
);

export default AutoFillGrid;
