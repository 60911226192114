import AutoFillGrid from "@/components/AutoFillGrid";
import OrderListItem from "@/pages/HomePage/OrderListCard.tsx";
import { useOrdersQuery } from "@/queries/order.ts";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Box, Button, Divider, LinearProgress, Typography } from "@mui/material";
import type { PageParams } from "jsonapi-zod-query";
import { type ReactNode, useState } from "react";

const OrderList = (): ReactNode => {
    const [pageParams, setPageParams] = useState<PageParams>();
    const ordersQuery = useOrdersQuery(pageParams);

    if (ordersQuery.isPending) {
        return <LinearProgress />;
    }

    if (ordersQuery.isError) {
        throw ordersQuery.error;
    }

    if (ordersQuery.data.data.length === 0) {
        return <Typography>We have no orders for you on record yet.</Typography>;
    }

    return (
        <>
            <AutoFillGrid minWidth={300}>
                {ordersQuery.data.data.map((order) => (
                    <OrderListItem key={order.id} order={order} />
                ))}
            </AutoFillGrid>

            {(ordersQuery.data.pageParams.prev || ordersQuery.data.pageParams.next) && (
                <>
                    <Divider />
                    <Box sx={{ mt: 2, textAlign: "center" }}>
                        <Button
                            variant="text"
                            startIcon={<KeyboardArrowLeftIcon />}
                            disabled={!ordersQuery.data.pageParams.prev}
                            onClick={() => {
                                if (ordersQuery.data.pageParams.prev) {
                                    setPageParams(ordersQuery.data.pageParams.prev);
                                }
                            }}
                        >
                            Previous
                        </Button>
                        <Button
                            variant="text"
                            endIcon={<KeyboardArrowRightIcon />}
                            disabled={!ordersQuery.data.pageParams.next}
                            onClick={() => {
                                if (ordersQuery.data.pageParams.next) {
                                    setPageParams(ordersQuery.data.pageParams.next);
                                }
                            }}
                        >
                            Next
                        </Button>
                    </Box>
                </>
            )}
        </>
    );
};

export default OrderList;
