import TermsAndConditionsHandler from "@/components/TermsAndConditionsHandler/index.ts";
import { useAuth0 } from "@auth0/auth0-react";
import {
    AppBar,
    Avatar,
    Box,
    Button,
    IconButton,
    Menu,
    MenuItem,
    Skeleton,
    Toolbar,
    Typography,
} from "@mui/material";
import { bindMenu, bindTrigger, usePopupState } from "material-ui-popup-state/hooks";
import type { ReactNode } from "react";
import { Outlet, useNavigate } from "react-router-dom";

const Layout = (): ReactNode => {
    const { user, isLoading, logout } = useAuth0();
    const navigate = useNavigate();
    const popupState = usePopupState({ variant: "popover", popupId: "userMenu" });

    return (
        <>
            <AppBar position="static">
                <Toolbar>
                    <Typography variant="h6" component="div" sx={{ mr: "auto" }}>
                        SBI Customer Portal
                    </Typography>

                    {isLoading ? (
                        <Skeleton
                            variant="circular"
                            animation="wave"
                            width={40}
                            height={40}
                            sx={{ m: 1 }}
                        />
                    ) : user ? (
                        <>
                            <IconButton {...bindTrigger(popupState)}>
                                <Avatar alt={user.name} src={user.picture} />
                            </IconButton>
                            <Menu {...bindMenu(popupState)}>
                                <MenuItem
                                    onClick={() => {
                                        void logout();
                                    }}
                                >
                                    Logout
                                </MenuItem>
                            </Menu>
                        </>
                    ) : (
                        <Button
                            color="inherit"
                            onClick={() => {
                                navigate("/initiate-login");
                            }}
                        >
                            Login
                        </Button>
                    )}
                </Toolbar>
            </AppBar>
            <Box component="main" sx={{ py: 2 }}>
                <Outlet />
            </Box>

            <TermsAndConditionsHandler />
        </>
    );
};

export default Layout;
