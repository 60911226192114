import useAuthenticatedFetch from "@/hooks/useAuthenticatedFetch.ts";
import { apiUrl } from "@/utils/api.ts";
import { type UseQueryResult, useQuery } from "@tanstack/react-query";
import {
    createDataSelector,
    createNullableResourceSelector,
    handleJsonApiError,
} from "jsonapi-zod-query";
import { z } from "zod";

const billingInformationAttributesSchema = z.object({
    companyName: z.string(),
    firstName: z.string(),
    lastName: z.string(),
    addressLineOne: z.string(),
    addressLineTwo: z.string(),
    city: z.string(),
    zipCode: z.string(),
    region: z.string(),
    countryCode: z.string(),
    phoneNumber: z.string(),
    emailAddress: z.string(),
});

const billingInformationSelector = createDataSelector(
    createNullableResourceSelector({
        type: "billing_information",
        attributesSchema: billingInformationAttributesSchema,
    }),
);

export type BillingInformation = NonNullable<ReturnType<typeof billingInformationSelector>>;

export const useBillingInformationQuery = (
    enabled = true,
): UseQueryResult<BillingInformation | null> => {
    const fetch = useAuthenticatedFetch();

    return useQuery({
        queryKey: ["billing-information"],
        queryFn: async ({ signal }) => {
            const response = await fetch(apiUrl("/billing-information"), {
                signal,
                headers: {
                    accept: "application/vnd.api+json",
                },
            });
            await handleJsonApiError(response);
            return response.json();
        },
        select: billingInformationSelector,
        enabled,
    });
};
