import PostalAddress from "@/components/PostalAddress/index.ts";
import EditIcon from "@mui/icons-material/Edit.js";
import { Card, CardContent, CardHeader, IconButton, type SxProps, Typography } from "@mui/material";
import { parsePhoneNumber } from "libphonenumber-js";
import type { ReactNode } from "react";
import type { CustomerInformationFormValues } from "../CustomerInformationStep/index.ts";

type Props = {
    billingInformation: CustomerInformationFormValues["billingInformation"];
    onStepChange: (step: number) => void;
    sx?: SxProps;
};

const BillingInformationCard = ({ billingInformation, onStepChange, sx }: Props): ReactNode => {
    return (
        <Card sx={sx}>
            <CardHeader
                title="Billing Information"
                action={
                    <IconButton
                        onClick={() => {
                            onStepChange(0);
                        }}
                    >
                        <EditIcon />
                    </IconButton>
                }
            />
            <CardContent>
                <PostalAddress address={billingInformation} sx={{ mb: 2 }} />
                <Typography>
                    <strong>Email Address:</strong> {billingInformation.emailAddress}
                    <br />
                    <strong>Phone number:</strong>{" "}
                    {parsePhoneNumber(billingInformation.phoneNumber).formatInternational()}
                    {billingInformation.poNumber !== "" && (
                        <>
                            <br />
                            <strong>PO number:</strong> {billingInformation.poNumber}
                        </>
                    )}
                </Typography>
            </CardContent>
        </Card>
    );
};

export default BillingInformationCard;
