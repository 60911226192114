import { useAuth0 } from "@auth0/auth0-react";
import { Container, LinearProgress } from "@mui/material";
import type { ReactNode } from "react";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

const InitiateLoginPage = (): ReactNode => {
    const { loginWithRedirect } = useAuth0();
    const [searchParams] = useSearchParams();
    const signUp = searchParams.get("signup") !== null;

    useEffect(() => {
        void loginWithRedirect({
            openUrl: (url) => window.location.replace(url),
            authorizationParams: {
                screen_hint: signUp ? "signup" : undefined,
            },
        });
    }, [loginWithRedirect, signUp]);

    return (
        <Container>
            <LinearProgress />
        </Container>
    );
};

export default InitiateLoginPage;
