import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft.js";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight.js";
import { Button, MobileStepper } from "@mui/material";
import type { ReactNode } from "react";

export type OrderStepperProps = {
    activeStep: number;
    onBackClick?: () => void;
    onNextClick?: () => void;
    nextDisabled?: boolean;
    nextLabel?: string;
};

const OrderStepper = ({
    activeStep,
    onBackClick,
    onNextClick,
    nextDisabled,
    nextLabel = "Next",
}: OrderStepperProps): ReactNode => {
    return (
        <MobileStepper
            variant="dots"
            elevation={3}
            steps={3}
            activeStep={activeStep}
            sx={{
                backgroundColor: "grey.200",
            }}
            nextButton={
                <Button
                    size="small"
                    disabled={nextDisabled}
                    type={onNextClick ? "button" : "submit"}
                    onClick={onNextClick}
                >
                    {nextLabel}
                    <KeyboardArrowRightIcon />
                </Button>
            }
            backButton={
                <Button size="small" disabled={onBackClick === undefined} onClick={onBackClick}>
                    <KeyboardArrowLeftIcon />
                    Back
                </Button>
            }
        />
    );
};

export default OrderStepper;
