import { Typography } from "@mui/material";
import type { ReactNode } from "react";
import { useFormState } from "react-hook-form";
import {
    type Option,
    type ServiceType,
    backingOptions,
    flammabilityCodeOptions,
    otherServiceOptions,
    resolveOptionLabel,
    serviceOptions,
    stainProtectionOptions,
} from "../options.ts";
import type { FabricUseFormReturn } from "./FabricForm.tsx";

type Props = {
    form: FabricUseFormReturn;
    fieldIndex: number;
};

const getTitle = (serviceType: ServiceType, options: Option[], value: string) => {
    const serviceName = resolveOptionLabel(serviceOptions, serviceType);
    const optionLabel = options.find((option) => option.value === value)?.label ?? null;

    if (!optionLabel) {
        return serviceName;
    }

    return `${serviceName} - ${optionLabel}`;
};

const ServiceTitle = ({ form, fieldIndex }: Props): ReactNode => {
    const serviceType = form.watch(`services.${fieldIndex}.type`) as ServiceType;
    const flammabilityCode = form.watch(`services.${fieldIndex}.flammabilityCode`);
    const otherService = form.watch(`services.${fieldIndex}.otherService`);
    const backing = form.watch(`services.${fieldIndex}.backing`);
    const stainProtection = form.watch(`services.${fieldIndex}.stainProtection`);

    switch (serviceType) {
        case "flame_retardant":
        case "lab_service": {
            return getTitle(serviceType, flammabilityCodeOptions, flammabilityCode);
        }

        case "other_service": {
            return getTitle(serviceType, otherServiceOptions, otherService);
        }

        case "backing": {
            return getTitle(serviceType, backingOptions, backing);
        }

        case "lamination_service": {
            return resolveOptionLabel(serviceOptions, serviceType);
        }

        case "stain_protection": {
            return getTitle(serviceType, stainProtectionOptions, stainProtection);
        }
    }
};

const ServiceHeader = ({ form, fieldIndex }: Props): ReactNode => {
    const { errors } = useFormState({ control: form.control });
    const hasError = Boolean(errors.services?.[fieldIndex]);

    return (
        <Typography color={hasError ? "error" : undefined}>
            <ServiceTitle form={form} fieldIndex={fieldIndex} />
        </Typography>
    );
};

export default ServiceHeader;
