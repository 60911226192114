import SummaryStep from "@/pages/OrderPage/SummaryStep/index.ts";
import { Container } from "@mui/material";
import type { ReactNode } from "react";
import { useState } from "react";
import CustomerInformationStep, {
    type CustomerInformationFormValues,
} from "./CustomerInformationStep/index.ts";
import FabricStep, { type FabricFormValues } from "./FabricStep/index.ts";

const OrderPage = (): ReactNode => {
    const [activeStep, setActiveStep] = useState(0);
    const [customerInformation, setCustomerInformation] = useState<CustomerInformationFormValues>();
    const [fabric, setFabric] = useState<FabricFormValues>();

    return (
        <Container>
            {activeStep === 0 && (
                <CustomerInformationStep
                    stepperProps={{
                        activeStep: activeStep,
                    }}
                    existingValues={customerInformation}
                    onSubmit={(values) => {
                        setCustomerInformation(values);
                        setActiveStep(1);
                    }}
                />
            )}

            {activeStep === 1 && (
                <FabricStep
                    stepperProps={{
                        activeStep: activeStep,
                        onBackClick: () => {
                            setActiveStep(0);
                        },
                    }}
                    existingValues={fabric}
                    onSubmit={(values) => {
                        setFabric(values);
                        setActiveStep(2);
                    }}
                />
            )}

            {activeStep === 2 && (
                <SummaryStep
                    stepperProps={{
                        activeStep: activeStep,
                        onBackClick: () => {
                            setActiveStep(1);
                        },
                    }}
                    customerInformation={customerInformation as CustomerInformationFormValues}
                    fabric={fabric as FabricFormValues}
                    onStepChange={(step) => {
                        setActiveStep(step);
                    }}
                />
            )}
        </Container>
    );
};

export default OrderPage;
