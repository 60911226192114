import useAuthenticatedFetch from "@/hooks/useAuthenticatedFetch.ts";
import { apiUrl } from "@/utils/api.ts";
import { useAuth0 } from "@auth0/auth0-react";
import { type UseMutationResult, useMutation } from "@tanstack/react-query";
import { handleJsonApiError } from "jsonapi-zod-query";
import { z } from "zod";
import type { CustomerInformation, Fabric } from "./order-submission.ts";

type CreateApprovalDocumentValues = {
    attributes: {
        customerInformation: CustomerInformation;
        fabric: Fabric;
        draft?: boolean;
    };
};

const createApprovalDocumentResultSchema = z.object({
    meta: z.object({
        presignedUrl: z.string(),
    }),
});

export const useCreateApprovalDocumentMutation = (): UseMutationResult<
    string,
    Error,
    CreateApprovalDocumentValues
> => {
    const { isAuthenticated } = useAuth0();
    const authenticatedFetch = useAuthenticatedFetch();
    const fetch = isAuthenticated ? authenticatedFetch : window.fetch;

    return useMutation({
        mutationFn: async (values) => {
            const response = await fetch(apiUrl("/approval-documents"), {
                method: "POST",
                headers: {
                    "content-type": "application/vnd.api+json",
                    accept: "application/vnd.api+json",
                },
                body: JSON.stringify({
                    data: {
                        type: "approval_document",
                        attributes: values.attributes,
                    },
                }),
            });
            await handleJsonApiError(response);

            const raw = await response.json();
            const result = createApprovalDocumentResultSchema.parse(raw);
            return result.meta.presignedUrl;
        },
    });
};
