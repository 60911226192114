import PostalAddress from "@/components/PostalAddress/index.ts";
import type { ShippingAddress } from "@/queries/shipping-addresses.ts";
import { Alert, MenuItem, Paper, Stack, Typography } from "@mui/material";
import { RhfRadioGroup, RhfTextField } from "mui-rhf-integration";
import { useEffect } from "react";
import type { ReactNode } from "react";
import { useWatch } from "react-hook-form";
import { z } from "zod";
import {
    carrierOptions,
    fedexServiceOptions,
    paymentOptions,
    shippingInsuranceOptions,
    upsServiceOptions,
} from "../options.ts";
import AddressFieldSet, { addressSchema } from "./AddressFieldSet.tsx";
import type { CustomerInformationUseFormReturn } from "./CustomerInformationForm.tsx";
import SameAsBillingSwitch from "./SameAsBillingSwitch.tsx";
import ShippingAddressSelect from "./ShippingAddressSelect.tsx";

export const shippingInformationSchema = z
    .object({
        shippingAddress: z.discriminatedUnion("type", [
            z
                .object({ type: z.literal("new") })
                .merge(addressSchema.omit({ firstName: true, lastName: true })),
            z.object({ type: z.literal("stored"), id: z.string() }),
            z.object({ type: z.literal("same_as_billing") }),
        ]),
        attention: z.string().trim().default(""),
        service: z.string().trim().min(1),
        shippingInsurance: z.discriminatedUnion("type", [
            z.object({
                type: z.literal("standard"),
            }),
            z.object({
                type: z.literal("custom"),
                amount: z
                    .string()
                    .trim()
                    .transform((value, context) => {
                        const amount = Number.parseInt(value);

                        if (Number.isNaN(amount) || amount.toString() !== value || amount < 0) {
                            context.addIssue({
                                code: z.ZodIssueCode.custom,
                                message: "Invalid amount",
                            });
                            return z.NEVER;
                        }

                        return amount;
                    }),
            }),
        ]),
    })
    .and(
        z.discriminatedUnion("carrier", [
            z.object({
                carrier: z.literal("fedex"),
                shippingAccountNumber: z.string().trim().min(1),
            }),
            z.object({ carrier: z.enum(["ups", "other"]) }),
        ]),
    )
    .and(
        z.discriminatedUnion("payment", [
            z.object({ payment: z.literal("prepaid") }),
            z.object({
                payment: z.literal("collect"),
                shippingAccountNumber: z.string().trim().min(1),
            }),
            z.object({
                payment: z.literal("third_party"),
                shippingAccountNumber: z.string().trim().min(1),
            }),
        ]),
    );

type Props = {
    form: CustomerInformationUseFormReturn;
    shippingAddresses: ShippingAddress[];
};

const ShippingInformationFieldSet = ({ form, shippingAddresses }: Props): ReactNode => {
    const selectedShippingAddress = useWatch({
        control: form.control,
        name: "selectedShippingAddress",
    });
    const shippingInformationType = useWatch({
        control: form.control,
        name: "shippingInformation.shippingAddress.type",
    });
    const carrier = useWatch({ control: form.control, name: "shippingInformation.carrier" });
    const payment = useWatch({
        control: form.control,
        name: "shippingInformation.payment",
    });
    const shippingInsuranceType = useWatch({
        control: form.control,
        name: "shippingInformation.shippingInsurance.type",
    });

    useEffect(() => {
        const subscription = form.watch((value, { name, type }) => {
            if (type !== "change" || name !== "shippingInformation.carrier") {
                return;
            }

            if (
                value.shippingInformation?.payment !== "third_party" &&
                value.shippingInformation?.carrier === "fedex"
            ) {
                // biome-ignore lint/suspicious/noExplicitAny: Reset to empty
                form.setValue("shippingInformation.payment", null as any);
            }

            form.setValue("shippingInformation.service", "");
        });

        return () => subscription.unsubscribe();
    }, [form.watch, form.setValue]);

    return (
        <Stack spacing={2}>
            {shippingAddresses.length > 0 ? (
                <ShippingAddressSelect form={form} shippingAddresses={shippingAddresses} />
            ) : (
                <SameAsBillingSwitch form={form} />
            )}

            {shippingInformationType === "new" ? (
                <AddressFieldSet
                    prefix="shippingInformation.shippingAddress"
                    control={form.control}
                    attentionField={
                        <RhfTextField
                            control={form.control}
                            label="Attention"
                            name="shippingInformation.attention"
                        />
                    }
                />
            ) : (
                <RhfTextField
                    control={form.control}
                    label="Attention"
                    name="shippingInformation.attention"
                />
            )}

            {"companyName" in selectedShippingAddress && (
                <Paper variant="outlined" sx={{ p: 2 }}>
                    <PostalAddress address={selectedShippingAddress} />
                </Paper>
            )}

            <Alert severity="info">
                <Typography variant="inherit">
                    If you do not have your own account for shipping, please select UPS as the
                    Carrier, Ground as the Service and PrePaid as the Payment.
                </Typography>
                <Typography variant="inherit">
                    If selecting FedEx as the carrier, please select “Third Party” as Payment and
                    include your FedEx Account Number.
                </Typography>
            </Alert>

            <Stack spacing={2} direction={{ xs: "column", sm: "row" }}>
                <RhfTextField
                    control={form.control}
                    label="Carrier"
                    name="shippingInformation.carrier"
                    select
                    required
                    sx={{ width: { sm: "33%" } }}
                >
                    {carrierOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </RhfTextField>

                <RhfTextField
                    control={form.control}
                    label="Service"
                    name="shippingInformation.service"
                    select={carrier !== "other"}
                    required
                    sx={{ width: { sm: "33%" } }}
                    helperText={
                        carrier === "other"
                            ? "Please include the carrier name as well as the service type"
                            : undefined
                    }
                >
                    {carrier === "fedex" &&
                        fedexServiceOptions.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}

                    {carrier === "ups" &&
                        upsServiceOptions.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                </RhfTextField>

                <RhfTextField
                    control={form.control}
                    label="Payment"
                    name="shippingInformation.payment"
                    select
                    required
                    sx={{ width: { sm: "33%" } }}
                >
                    {paymentOptions
                        .filter((option) => carrier !== "fedex" || option.value === "third_party")
                        .map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                </RhfTextField>
            </Stack>

            {(carrier === "fedex" || (payment !== undefined && payment !== "prepaid")) && (
                <RhfTextField
                    control={form.control}
                    label="Shipping Account Number"
                    name="shippingInformation.shippingAccountNumber"
                    required
                />
            )}

            <RhfRadioGroup
                control={form.control}
                name="shippingInformation.shippingInsurance.type"
                label="Shipping Insurance Option"
                options={shippingInsuranceOptions}
            />

            {shippingInsuranceType === "custom" && (
                <RhfTextField
                    control={form.control}
                    label="Custom amount"
                    name="shippingInformation.shippingInsurance.amount"
                    required
                    helperText="
                       I opt to insure this order for the amount listed above, in lieu of the
                       standard 500 insurance. By providing this custom amount, I acknowledge that
                       for each 100 insured above $500, there is an additional shipping charge of
                       $1.50.
                    "
                />
            )}
        </Stack>
    );
};

export default ShippingInformationFieldSet;
