import EditIcon from "@mui/icons-material/Edit";
import {
    Alert,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    IconButton,
    type SxProps,
    Typography,
} from "@mui/material";
import { Fragment } from "react";
import type { ReactNode } from "react";
import type { FabricFormValues } from "../FabricStep/index.ts";
import { fabricFaceOptions, resolveOptionLabel, trimOptions } from "../options.ts";
import FabricServiceDisplay from "./FabricServiceDisplay.tsx";

type Props = {
    fabric: FabricFormValues;
    onStepChange: (step: number) => void;
    sx?: SxProps;
};

const FabricCard = ({ fabric, onStepChange, sx }: Props): ReactNode => {
    return (
        <Card sx={sx}>
            <CardHeader
                title="Fabric"
                action={
                    <IconButton
                        onClick={() => {
                            onStepChange(1);
                        }}
                    >
                        <EditIcon />
                    </IconButton>
                }
            />
            <CardContent>
                <Grid container columnSpacing={2}>
                    <Grid item xs={12} sm={6} md={4}>
                        <strong>Yards:</strong> {fabric.yards}
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <strong>Fiber Content:</strong> {fabric.fiberContent}
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <strong>Pattern:</strong> {fabric.pattern}
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <strong>Color:</strong> {fabric.color}
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <strong>Manufacturer:</strong> {fabric.manufacturer}
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <strong>Trim:</strong>{" "}
                        {fabric.trim === null
                            ? "No Trim"
                            : resolveOptionLabel(trimOptions, fabric.trim)}
                    </Grid>
                    {fabric.fabricFace && (
                        <Grid item xs={12} sm={6} md={4}>
                            <strong>Fabric Face:</strong>{" "}
                            {resolveOptionLabel(fabricFaceOptions, fabric.fabricFace)}
                        </Grid>
                    )}
                </Grid>

                <Typography sx={{ mt: 2, whiteSpace: "pre-line" }}>
                    <strong>Sidemarks:</strong>
                    <br />
                    {fabric.sidemarks}
                </Typography>

                {fabric.specialInstructions !== "" && (
                    <Typography sx={{ mt: 2, whiteSpace: "pre-line" }}>
                        <strong>Special Instructions:</strong>
                        <br />
                        {fabric.specialInstructions}
                    </Typography>
                )}

                {fabric.fabricFace === "unknown" && (
                    <Alert severity="info" sx={{ my: 2 }}>
                        Facing must be determined for order to be processed.
                    </Alert>
                )}

                {fabric.services.map((service) => (
                    <Fragment key={service.fieldId}>
                        <Divider sx={{ my: 2 }} />
                        <FabricServiceDisplay service={service} />
                    </Fragment>
                ))}
            </CardContent>
        </Card>
    );
};

export default FabricCard;
