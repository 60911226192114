import { Typography } from "@mui/material";
import type { ReactNode } from "react";
import type { FabricFormValues } from "../FabricStep/index.ts";
import {
    backingOptions,
    flammabilityCodeOptions,
    otherServiceOptions,
    resolveOptionLabel,
    rushOptions,
    serviceOptions,
    stainProtectionOptions,
} from "../options.ts";

type Props = {
    service: FabricFormValues["services"][number];
};

const FabricServiceDisplay = ({ service }: Props): ReactNode => {
    return (
        <>
            <Typography variant="h6">{resolveOptionLabel(serviceOptions, service.type)}</Typography>

            {"rush" in service && service.rush && (
                <>
                    <strong>Rush:</strong> {resolveOptionLabel(rushOptions, service.rush)}
                    <br />
                </>
            )}

            {"flammabilityCode" in service && (
                <>
                    <strong>Flammability Code:</strong>{" "}
                    {resolveOptionLabel(flammabilityCodeOptions, service.flammabilityCode)}
                    <br />
                </>
            )}

            {"stainProtection" in service && (
                <>
                    <strong>Stain Protection:</strong>{" "}
                    {resolveOptionLabel(stainProtectionOptions, service.stainProtection)}
                    <br />
                </>
            )}

            {"otherService" in service && (
                <>
                    <strong>Other Service:</strong>{" "}
                    {resolveOptionLabel(otherServiceOptions, service.otherService)}
                    <br />
                </>
            )}

            {"backing" in service && (
                <>
                    <strong>Backing:</strong> {resolveOptionLabel(backingOptions, service.backing)}
                    <br />
                </>
            )}
        </>
    );
};

export default FabricServiceDisplay;
