import { useBillingInformationQuery } from "@/queries/billing-information.ts";
import { useShippingAddressesQuery } from "@/queries/shipping-addresses.ts";
import { useAuth0 } from "@auth0/auth0-react";
import { Alert, LinearProgress, Link, Typography } from "@mui/material";
import type { ReactNode } from "react";
import { Link as RouterLink } from "react-router-dom";
import OrderStepper, { type OrderStepperProps } from "../OrderStepper.tsx";
import CustomerInformationForm, {
    type CustomerInformationFormValues,
} from "./CustomerInformationForm.tsx";

type Props = {
    stepperProps: OrderStepperProps;
    existingValues?: CustomerInformationFormValues;
    onSubmit: (values: CustomerInformationFormValues) => void;
};

const CustomerInformationStep = ({ stepperProps, existingValues, onSubmit }: Props): ReactNode => {
    const { isAuthenticated, isLoading } = useAuth0();
    const billingInformationQuery = useBillingInformationQuery(isAuthenticated);
    const shippingAddressesQuery = useShippingAddressesQuery(isAuthenticated);

    if (
        isLoading ||
        (isAuthenticated && (billingInformationQuery.isPending || shippingAddressesQuery.isPending))
    ) {
        return (
            <>
                <Typography variant="h5" sx={{ mb: 2 }}>
                    Customer Information
                </Typography>
                <LinearProgress />
                <OrderStepper {...stepperProps} nextDisabled />
            </>
        );
    }

    if (billingInformationQuery.isError) {
        throw billingInformationQuery.error;
    }

    if (shippingAddressesQuery.isError) {
        throw shippingAddressesQuery.error;
    }

    const defaultValues: Partial<CustomerInformationFormValues> = existingValues ?? {
        billingInformation: billingInformationQuery.data
            ? {
                  ...billingInformationQuery.data,
                  poNumber: "",
              }
            : undefined,
    };

    return (
        <>
            <Typography variant="h5" sx={{ mb: 2 }}>
                Customer Information
            </Typography>

            {!isAuthenticated && (
                <Alert severity="info" sx={{ mb: 2 }}>
                    You are not signed in, this order will be placed anonymously. If you already
                    have an account,{" "}
                    <Link component={RouterLink} to="/initiate-login">
                        log in now
                    </Link>
                    . You can also{" "}
                    <Link component={RouterLink} to="/initiate-login?signup">
                        create a new account
                    </Link>
                    .
                </Alert>
            )}

            <Alert severity="info" sx={{ mb: 2 }}>
                If you are wanting a quote, please email{" "}
                <Link href="mailto:customerservice@sbifinishing.com">
                    customerservice@sbifinishing.com
                </Link>
                .
            </Alert>

            <CustomerInformationForm
                existingValues={defaultValues}
                shippingAddresses={shippingAddressesQuery.data ?? []}
                stepperProps={stepperProps}
                onSubmit={onSubmit}
            />
        </>
    );
};

export default CustomerInformationStep;
