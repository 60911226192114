import type { SxProps, TextFieldProps } from "@mui/material";
import { countries } from "countries-list";
import { RhfAutocomplete } from "mui-rhf-integration";
import type { Control, FieldPath, FieldValues } from "react-hook-form";

const collator = new Intl.Collator("en-US", { sensitivity: "base" });

export const countryOptions = Object.entries(countries)
    .map(([countryCode, country]) => ({
        countryCode: countryCode,
        label: country.name,
        name: country.name,
        token: country.name.toLowerCase(),
    }))
    .sort((a, b) => collator.compare(a.name, b.name));

type Props<
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = {
    control: Control<TFieldValues>;
    name: TName;
    textFieldProps?: Omit<TextFieldProps, "error" | "onChange" | "onBlur" | "value" | "inputRef">;
    multiple?: boolean;
    limitTags?: number;
    sx?: SxProps;
};

const RhfCountrySelect = <
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
    control,
    name,
    textFieldProps,
    multiple,
    limitTags,
    sx,
}: Props<TFieldValues, TName>): JSX.Element => {
    return (
        <RhfAutocomplete
            control={control}
            name={name}
            options={countryOptions}
            freeSolo={false}
            multiple={multiple}
            limitTags={limitTags}
            getOptionLabel={(option) => option.label}
            optionToValue={(option) => option.countryCode}
            valueToOption={(value) => countryOptions.find((option) => option.countryCode === value)}
            filterOptions={(options, state) => {
                if (state.inputValue === "") {
                    return options;
                }

                const token = state.inputValue.toLowerCase();
                return options.filter((option) => option.token.includes(token));
            }}
            textFieldProps={textFieldProps}
            sx={sx}
        />
    );
};

export default RhfCountrySelect;
