import OrderStatusChip from "@/pages/HomePage/OrderStatusChip.tsx";
import type { Order } from "@/queries/order.ts";
import { DateTimeFormatter } from "@js-joda/core";
import { Card, CardContent, Link, Typography } from "@mui/material";
import type { ReactNode } from "react";

type Props = {
    order: Order;
};

const dateFormatter = DateTimeFormatter.ofPattern("MM/dd/yyyy");

const OrderListItem = ({ order }: Props): ReactNode => {
    return (
        <Card sx={{ height: "100%" }}>
            <CardContent>
                <Typography variant="h6" noWrap>
                    {order.projectName}
                </Typography>
                <Typography variant="body2">
                    {order.dueOn?.format(dateFormatter) ?? "No due date"}
                </Typography>

                <OrderStatusChip status={order.status} size="small" sx={{ my: 1 }} />

                <Typography>{order.fabric.yards} yards</Typography>
                <Typography>
                    <strong>Fiber content:</strong> {order.fabric.fiberContent}
                </Typography>
                <Typography>
                    <strong>Pattern:</strong> {order.fabric.pattern}
                </Typography>
                <Typography>
                    <strong>Color:</strong> {order.fabric.color}
                </Typography>

                {order.tracking && (
                    <Typography sx={{ mt: 2 }}>
                        <Link href={order.tracking.trackingUrl} target="_blank" rel="noreferrer">
                            {order.tracking.carrierName} #{order.tracking.trackingNumber}
                        </Link>
                    </Typography>
                )}
            </CardContent>
        </Card>
    );
};

export default OrderListItem;
