import OrderList from "@/pages/HomePage/OrderList.tsx";
import { useAuth0 } from "@auth0/auth0-react";
import { Box, Button, Container, Divider, LinearProgress, Stack, Typography } from "@mui/material";
import type { ReactNode } from "react";
import { useNavigate } from "react-router-dom";

const HomePage = (): ReactNode => {
    const { isAuthenticated, isLoading } = useAuth0();
    const navigate = useNavigate();

    if (isLoading) {
        return (
            <Container>
                <LinearProgress />
            </Container>
        );
    }

    if (!isAuthenticated) {
        return (
            <Container maxWidth="xs">
                <Typography sx={{ mb: 2 }}>Welcome to the SBI Customer Portal!</Typography>

                <Stack spacing={2}>
                    <Button
                        variant="contained"
                        onClick={() => {
                            navigate("/order");
                        }}
                    >
                        Place new order
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => {
                            navigate("/initiate-login");
                        }}
                    >
                        Login
                    </Button>
                </Stack>
            </Container>
        );
    }

    return (
        <Container>
            <Box sx={{ display: "flex", mb: 2 }}>
                <Typography variant="h5" sx={{ mr: "auto" }}>
                    Welcome!
                </Typography>
                <Button
                    variant="contained"
                    onClick={() => {
                        navigate("/order");
                    }}
                >
                    Place order
                </Button>
            </Box>

            <Divider sx={{ mb: 2 }} />
            <Typography variant="h6">Orders</Typography>
            <OrderList />
        </Container>
    );
};

export default HomePage;
