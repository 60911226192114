import ConfirmDialog from "@/components/ConfirmDialog/index.ts";
import { errorMap } from "@/utils/zod.ts";
import { zodResolver } from "@hookform/resolvers/zod";
import { Alert, Box, FormControlLabel, FormHelperText, MenuItem, Stack } from "@mui/material";
import { RhfSwitch, RhfTextField } from "mui-rhf-integration";
import type { ReactNode } from "react";
import { useEffect, useMemo, useState } from "react";
import { useConfirm } from "react-confirm-hook";
import { type DeepPartial, type UseFormReturn, useForm } from "react-hook-form";
import { z } from "zod";
import OrderStepper, { type OrderStepperProps } from "../OrderStepper.tsx";
import { type ServiceType, fabricFaceOptions, trimOptions } from "../options.ts";
import { serviceSchema } from "./ServiceFieldSet.tsx";
import ServicesList from "./ServicesList.tsx";

const schema = z
    .object({
        yards: z
            .string()
            .trim()
            .min(1)
            .regex(/^[1-9]\d*(?:\.\d{1,2})?$/, "Invalid number")
            .transform((value) => {
                if (!value.includes(".")) {
                    return value;
                }

                return value.replace(/0+$/, "").replace(/\.$/, "");
            }),
        fiberContent: z.string().trim().min(1),
        specialCare: z.boolean().default(false),
        pattern: z.string().trim().min(1),
        plaidOrStriped: z.boolean().default(false),
        color: z.string().trim().min(1),
        manufacturer: z.string().trim().min(1),
        trim: z.string().transform((value) => (value === "no_trim" ? null : value)),
        fabricFace: z.string().optional(),
        sidemarks: z.string().trim().min(1),
        specialInstructions: z.string().trim().default(""),
        services: z.array(serviceSchema).min(1),
    })
    .superRefine((values, context) => {
        if (
            values.services.some((service) =>
                ["backing", "lamination_service"].includes(service.type),
            ) &&
            !values.fabricFace
        ) {
            context.addIssue({
                code: z.ZodIssueCode.custom,
                path: ["fabricFace"],
                message: "Required",
            });
        }
    });

export const trimSupportedServices: ServiceType[] = ["flame_retardant", "stain_protection"];

type FieldValues = z.input<typeof schema>;
type TransformedValues = z.output<typeof schema>;
export type FabricUseFormReturn = UseFormReturn<FieldValues, unknown, TransformedValues>;
export type FabricFormValues = TransformedValues;

const getDefaultValues = (existingValues: Partial<TransformedValues>): DeepPartial<FieldValues> => {
    return {
        yards: existingValues.yards,
        fiberContent: existingValues.fiberContent,
        specialCare: existingValues.specialCare,
        pattern: existingValues.pattern,
        plaidOrStriped: existingValues.plaidOrStriped,
        color: existingValues.color,
        manufacturer: existingValues.manufacturer,
        trim: existingValues.trim ?? "no_trim",
        sidemarks: existingValues.sidemarks,
        specialInstructions: existingValues.specialInstructions,
        services: existingValues.services?.map((service) => ({
            ...service,
            rush: "rush" in service ? service.rush ?? "no_rush" : undefined,
        })),
        fabricFace: existingValues.fabricFace,
    };
};

const fabricFaceServices: ServiceType[] = ["backing", "lamination_service"];

type Props = {
    existingValues: Partial<FabricFormValues>;
    onSubmit: (values: FabricFormValues) => void;
    stepperProps: OrderStepperProps;
};

const FabricForm = ({ onSubmit, stepperProps, existingValues }: Props): ReactNode => {
    const defaultValues = useMemo(() => {
        return getDefaultValues(existingValues);
    }, [existingValues]);

    const form = useForm<FieldValues, unknown, TransformedValues>({
        resolver: zodResolver(schema, { errorMap }),
        defaultValues,
    });
    const confirm = useConfirm(ConfirmDialog);
    const [fabricFaceEnabled, setFabricFaceEnabled] = useState(() => {
        return defaultValues.services?.some(
            (service) => service?.type && fabricFaceServices.includes(service.type),
        );
    });

    useEffect(() => {
        const subscription = form.watch((value, { name }) => {
            if (name !== "services") {
                return;
            }

            const fabricFaceEnabled = value.services?.some(
                (service) => service?.type && fabricFaceServices.includes(service.type),
            );

            if (!fabricFaceEnabled) {
                form.setValue("fabricFace", undefined);
                setFabricFaceEnabled(false);
                return;
            }

            setFabricFaceEnabled(true);
        });

        return () => subscription.unsubscribe();
    }, [form.setValue, form.watch]);

    useEffect(() => {
        const subscription = form.watch((value, { name, type }) => {
            if (type !== "change" || name !== "trim" || value.trim === "no_trim") {
                return;
            }

            const selectedServiceTypes =
                value.services?.reduce<ServiceType[]>((services, service) => {
                    if (service?.type) {
                        services.push(service.type);
                    }

                    return services;
                }, []) ?? [];
            const disallowedServices = selectedServiceTypes.filter(
                (service) => !trimSupportedServices.includes(service),
            );

            if (disallowedServices.length === 0) {
                return;
            }

            confirm({
                title: "Unsupported services selected",
                message: `
                    You have selected services which are not supported with trim. Enabling trim will
                    remove those services. Do you want to continue?
                `,
                onConfirm: () => {
                    form.setValue(
                        "services",
                        (value.services as FieldValues["services"]).filter((service) =>
                            trimSupportedServices.includes(service.type),
                        ),
                    );
                },
                onCancel: () => {
                    form.setValue("trim", "no_trim");
                },
            });
        });

        return () => subscription.unsubscribe();
    }, [form.setValue, form.watch, confirm]);

    const fabricFace = form.watch("fabricFace");

    return (
        <Box sx={{ pb: 8 }} component="form" onSubmit={form.handleSubmit(onSubmit)} noValidate>
            <Stack spacing={2}>
                <Alert severity="info">
                    For samples, please send a fabric size between 6in X 6in and 1/2 yard. If for a
                    Flame Retardant sample, the fabric must be at least one full yard.
                </Alert>
                <Stack spacing={2} direction={{ xs: "column", sm: "row" }}>
                    <RhfTextField
                        control={form.control}
                        name="yards"
                        label="Yards"
                        required
                        sx={{ flexGrow: 1, width: "50%" }}
                        InputProps={{
                            inputMode: "numeric",
                        }}
                        autoComplete="off"
                    />
                    <RhfTextField
                        control={form.control}
                        name="fiberContent"
                        label="Fiber Content"
                        required
                        sx={{ flexGrow: 1, width: "50%" }}
                    />
                </Stack>
                <div>
                    <FormControlLabel
                        control={<RhfSwitch control={form.control} name="specialCare" />}
                        label="Fabric requires special care"
                    />
                    <FormHelperText>
                        Special care fabrics include velvet, silk, heat sensitive fabrics
                        (polypropylene) and embroideries.
                    </FormHelperText>
                </div>
                <Stack spacing={2} direction={{ xs: "column", sm: "row" }}>
                    <RhfTextField
                        control={form.control}
                        name="pattern"
                        label="Pattern"
                        required
                        sx={{ flexGrow: 1 }}
                    />
                    <RhfTextField
                        control={form.control}
                        name="color"
                        label="Color"
                        required
                        sx={{ flexGrow: 1 }}
                    />
                    <RhfTextField
                        control={form.control}
                        name="manufacturer"
                        label="Manufacturer"
                        required
                        sx={{ flexGrow: 1 }}
                    />
                </Stack>
                <div>
                    <FormControlLabel
                        control={<RhfSwitch control={form.control} name="plaidOrStriped" />}
                        label="Pattern is plaid or striped"
                    />
                </div>
                <RhfTextField control={form.control} name="trim" label="Trim" select>
                    <MenuItem value="no_trim">No Trim</MenuItem>
                    {trimOptions.map((option) => (
                        <MenuItem value={option.value} key={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </RhfTextField>
                <RhfTextField
                    control={form.control}
                    name="sidemarks"
                    label="Sidemarks"
                    required
                    multiline
                    minRows={5}
                />
                <RhfTextField
                    control={form.control}
                    label="Special Instructions"
                    name="specialInstructions"
                    multiline
                />
                <ServicesList form={form} />
                {form.formState.errors.services &&
                    !Array.isArray(form.formState.errors.services) && (
                        <Alert severity="error">You must select at least one service.</Alert>
                    )}
                {fabricFaceEnabled && (
                    <RhfTextField
                        control={form.control}
                        name="fabricFace"
                        label="Fabric Face"
                        required
                        select
                    >
                        {fabricFaceOptions.map((option) => (
                            <MenuItem value={option.value} key={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </RhfTextField>
                )}

                {fabricFaceEnabled && fabricFace === "unknown" && (
                    <Alert severity="info">
                        Facing must be determined for order to be processed.
                    </Alert>
                )}
            </Stack>

            <OrderStepper {...stepperProps} />
        </Box>
    );
};

export default FabricForm;
