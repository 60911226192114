import type { ShippingAddress } from "@/queries/shipping-addresses.ts";
import { RhfAutocomplete } from "mui-rhf-integration";
import { type ReactNode, useEffect, useMemo } from "react";
import type { CustomerInformationUseFormReturn } from "./CustomerInformationForm.tsx";

const CREATE_NEW_SHIPPING_ADDRESS_ID = "create-new-shipping-address";

export const CREATE_NEW_SHIPPING_ADDRESS_OPTION = {
    id: CREATE_NEW_SHIPPING_ADDRESS_ID,
    title: "Create new shipping address",
} as const;

type Props = {
    form: CustomerInformationUseFormReturn;
    shippingAddresses: ShippingAddress[];
};

const ShippingAddressSelect = ({ form, shippingAddresses }: Props): ReactNode => {
    const shippingAddressOptions = useMemo(() => {
        return [CREATE_NEW_SHIPPING_ADDRESS_OPTION, ...shippingAddresses];
    }, [shippingAddresses]);

    useEffect(() => {
        const subscription = form.watch((value, { name, type }) => {
            if (
                type !== "change" ||
                name !== "selectedShippingAddress" ||
                !value.selectedShippingAddress?.id
            ) {
                return;
            }

            if (value.selectedShippingAddress.id === CREATE_NEW_SHIPPING_ADDRESS_ID) {
                form.setValue("shippingInformation.shippingAddress.type", "new");
                return;
            }

            form.setValue("shippingInformation.shippingAddress.type", "stored");
            form.setValue(
                "shippingInformation.shippingAddress.id",
                value.selectedShippingAddress.id,
            );
        });

        return () => subscription.unsubscribe();
    }, [form.watch, form.setValue]);

    return (
        <RhfAutocomplete
            options={shippingAddressOptions}
            getOptionLabel={(option) => {
                if (typeof option === "string") {
                    return option;
                }

                if ("companyName" in option) {
                    return option.companyName;
                }

                return option.title;
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            control={form.control}
            name="selectedShippingAddress"
            disableClearable
            textFieldProps={{
                label: "Shipping Address",
            }}
        />
    );
};

export default ShippingAddressSelect;
