import { FormControlLabel } from "@mui/material";
import { RhfSwitch } from "mui-rhf-integration";
import { type ReactNode, useEffect } from "react";
import type { CustomerInformationUseFormReturn } from "./CustomerInformationForm.tsx";

type Props = {
    form: CustomerInformationUseFormReturn;
};

const SameAsBillingSwitch = ({ form }: Props): ReactNode => {
    useEffect(() => {
        const subscription = form.watch((value, { name, type }) => {
            if (type !== "change" || name !== "sameAsBilling") {
                return;
            }

            if (value.sameAsBilling) {
                form.setValue("shippingInformation.shippingAddress.type", "same_as_billing");
                return;
            }

            form.setValue("shippingInformation.shippingAddress.type", "new");
        });

        return () => subscription.unsubscribe();
    }, [form.watch, form.setValue]);

    return (
        <FormControlLabel
            control={<RhfSwitch control={form.control} name="sameAsBilling" />}
            label="Same as billing"
        />
    );
};

export default SameAsBillingSwitch;
