import { Typography } from "@mui/material";
import type { ReactNode } from "react";
import type { OrderStepperProps } from "../OrderStepper.tsx";
import FabricForm, { type FabricFormValues } from "./FabricForm.tsx";

type Props = {
    stepperProps: OrderStepperProps;
    existingValues?: FabricFormValues;
    onSubmit: (values: FabricFormValues) => void;
};

const FabricStep = ({ stepperProps, existingValues, onSubmit }: Props): ReactNode => {
    return (
        <>
            <Typography variant="h5" sx={{ mb: 2 }}>
                Fabric
            </Typography>
            <FabricForm
                stepperProps={stepperProps}
                existingValues={existingValues ?? {}}
                onSubmit={onSubmit}
            />
        </>
    );
};

export default FabricStep;
