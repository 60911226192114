import useAuthenticatedFetch from "@/hooks/useAuthenticatedFetch.ts";
import { apiUrl } from "@/utils/api.ts";
import { type UseQueryResult, useQuery } from "@tanstack/react-query";
import {
    type PageParams,
    createResourceCollectionSelector,
    handleJsonApiError,
    injectPageParams,
} from "jsonapi-zod-query";
import { z } from "zod";
import { zj } from "zod-joda";

const orderAttributesSchema = z.object({
    projectName: z.string(),
    fabric: z.object({
        yards: z.string().min(1),
        fiberContent: z.string().min(1),
        pattern: z.string().min(1),
        color: z.string().min(1),
    }),
    status: z.enum(["fabric_not_received", "on_hold", "in_production", "completed"]),
    dueOn: zj.localDate().nullable(),
    tracking: z
        .object({
            carrierName: z.string(),
            trackingNumber: z.string(),
            trackingUrl: z.string().url(),
        })
        .optional(),
});

const ordersSelector = createResourceCollectionSelector({
    type: "order",
    attributesSchema: orderAttributesSchema,
});

export type PaginatedOrders = ReturnType<typeof ordersSelector>;
export type Order = PaginatedOrders["data"][number];

export const useOrdersQuery = (pageParams?: PageParams): UseQueryResult<PaginatedOrders> => {
    const fetch = useAuthenticatedFetch();

    return useQuery({
        queryKey: ["orders", { pageParams }],
        queryFn: async ({ signal }) => {
            const url = apiUrl("/orders");
            injectPageParams(url, pageParams);

            const response = await fetch(url, {
                signal,
                headers: {
                    accept: "application/vnd.api+json",
                },
            });
            await handleJsonApiError(response);
            return response.json();
        },
        select: ordersSelector,
    });
};
