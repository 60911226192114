import type { Order } from "@/queries/order.ts";
import CheckIcon from "@mui/icons-material/Check";
import ErrorIcon from "@mui/icons-material/Error";
import PauseCircleIcon from "@mui/icons-material/PauseCircle";
import PendingIcon from "@mui/icons-material/Pending";
import { Chip, type ChipProps } from "@mui/material";
import type { ReactNode } from "react";
import { match } from "ts-pattern";

type Props = Omit<ChipProps, "label" | "color" | "icon"> & {
    status: Order["status"];
};

const OrderStatusChip = ({ status, ...rest }: Props): ReactNode => {
    return match(status)
        .with("fabric_not_received", () => (
            <Chip label="Fabric not received" color="error" icon={<ErrorIcon />} {...rest} />
        ))
        .with("on_hold", () => (
            <Chip label="On hold" color="warning" icon={<PauseCircleIcon />} {...rest} />
        ))
        .with("in_production", () => (
            <Chip label="In production" color="info" icon={<PendingIcon />} {...rest} />
        ))
        .with("completed", () => (
            <Chip label="Completed" color="success" icon={<CheckIcon />} {...rest} />
        ))
        .exhaustive();
};

export default OrderStatusChip;
