import { RhfCountrySelect } from "@/components/Form/index.ts";
import { Stack } from "@mui/material";
import { RhfTextField } from "mui-rhf-integration";
import type { ReactNode } from "react";
import type { Control, FieldPath, FieldValues } from "react-hook-form";
import { z } from "zod";

export const addressSchema = z.object({
    companyName: z.string().trim().min(1),
    firstName: z.string().trim().default(""),
    lastName: z.string().trim().default(""),
    addressLineOne: z.string().trim().min(1),
    addressLineTwo: z.string().trim().default(""),
    city: z.string().trim().min(1),
    zipCode: z.string().trim().min(1),
    region: z.string().trim().min(1),
    countryCode: z.string().min(1),
});

type Props<TFieldValues extends FieldValues, TPath extends FieldPath<TFieldValues>> = {
    prefix: TPath;
    control: Control<TFieldValues>;
    nameIncluded?: boolean;
    attentionField?: ReactNode;
};

const AddressFieldSet = <TFieldValues extends FieldValues, TPath extends FieldPath<TFieldValues>>({
    prefix,
    control,
    nameIncluded = false,
    attentionField,
}: Props<TFieldValues, TPath>): ReactNode => {
    return (
        <Stack spacing={2}>
            <RhfTextField
                control={control}
                label="Company Name"
                name={`${prefix}.companyName` as TPath}
                required
                autoComplete="organization"
            />
            {attentionField}
            {nameIncluded && (
                <Stack spacing={2} direction={{ xs: "column", sm: "row" }}>
                    <RhfTextField
                        control={control}
                        label="First Name"
                        name={`${prefix}.firstName` as TPath}
                        sx={{ width: { sm: "50%" } }}
                        autoComplete="given-name"
                    />
                    <RhfTextField
                        control={control}
                        label="Last Name"
                        name={`${prefix}.lastName` as TPath}
                        sx={{ width: { sm: "50%" } }}
                        autoComplete="family-name"
                    />
                </Stack>
            )}
            <RhfTextField
                control={control}
                label="Address Line 1"
                name={`${prefix}.addressLineOne` as TPath}
                required
                autoComplete="address-line1"
            />
            <RhfTextField
                control={control}
                label="Address Line 2"
                name={`${prefix}.addressLineTwo` as TPath}
                autoComplete="address-line2"
            />
            <Stack spacing={2} direction={{ xs: "column", sm: "row" }}>
                <RhfTextField
                    control={control}
                    label="City"
                    name={`${prefix}.city` as TPath}
                    required
                    autoComplete="address-level2"
                    sx={{ width: { sm: "50%" } }}
                />
                <RhfTextField
                    control={control}
                    label="State, Province or Region"
                    name={`${prefix}.region` as TPath}
                    required
                    autoComplete="address-level1"
                    sx={{ width: { sm: "50%" } }}
                    helperText="Enter N/A if the country does not have a state, province or region"
                />
            </Stack>
            <Stack spacing={2} direction={{ xs: "column", sm: "row" }}>
                <RhfTextField
                    control={control}
                    label="Zip"
                    name={`${prefix}.zipCode` as TPath}
                    required
                    autoComplete="postal-code"
                    sx={{ width: { sm: "50%" } }}
                />
                <RhfCountrySelect
                    control={control}
                    name={`${prefix}.countryCode` as TPath}
                    textFieldProps={{
                        label: "Country",
                        required: true,
                        autoComplete: "country",
                    }}
                    sx={{ width: { sm: "50%" } }}
                />
            </Stack>
        </Stack>
    );
};

export default AddressFieldSet;
