import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
    Accordion,
    AccordionActions,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Typography,
} from "@mui/material";
import { nanoid } from "nanoid";
import { type ReactNode, type SyntheticEvent, useState } from "react";
import { useFieldArray } from "react-hook-form";
import type { z } from "zod";
import AddServiceDialog from "./AddServiceDialog.tsx";
import type { FabricUseFormReturn } from "./FabricForm.tsx";
import ServiceFieldSet, { type serviceSchema } from "./ServiceFieldSet.tsx";
import ServiceHeader from "./ServiceHeader.tsx";

type Props = {
    form: FabricUseFormReturn;
};

const ServicesList = ({ form }: Props): ReactNode => {
    const { fields, append, remove } = useFieldArray({
        control: form.control,
        name: "services",
    });
    const [addServiceDialogOpen, setAddServiceDialogOpen] = useState(false);
    const [expanded, setExpanded] = useState<string | null>(null);

    const handleExpand = (id: string) => (_event: SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? id : null);
    };

    return (
        <div>
            <Box sx={{ display: "flex", mb: 2 }}>
                <Typography variant="h6" sx={{ mr: "auto" }}>
                    Services
                </Typography>
                <Button
                    onClick={() => {
                        setAddServiceDialogOpen(true);
                    }}
                    variant="contained"
                >
                    Add service
                </Button>
            </Box>

            <AddServiceDialog
                open={addServiceDialogOpen}
                onClose={() => {
                    setAddServiceDialogOpen(false);
                }}
                onSelect={(serviceType) => {
                    setAddServiceDialogOpen(false);
                    const fieldId = nanoid();
                    append({
                        fieldId,
                        type: serviceType,
                        rush: "no_rush",
                    } as unknown as z.input<typeof serviceSchema>);
                    setExpanded(fieldId);
                }}
                form={form}
            />

            {fields.length === 0 && <Typography>You haven't added any services yet.</Typography>}

            {fields.map((field, index) => (
                <Accordion
                    expanded={field.fieldId === expanded}
                    onChange={handleExpand(field.fieldId)}
                    key={field.fieldId}
                    slotProps={{
                        transition: { unmountOnExit: true },
                    }}
                >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <ServiceHeader form={form} fieldIndex={index} />
                    </AccordionSummary>
                    <AccordionDetails>
                        <ServiceFieldSet form={form} fieldIndex={index} />
                    </AccordionDetails>
                    <AccordionActions>
                        <Button
                            color="error"
                            onClick={() => {
                                remove(index);
                            }}
                        >
                            Remove
                        </Button>
                    </AccordionActions>
                </Accordion>
            ))}
        </div>
    );
};

export default ServicesList;
